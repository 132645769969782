/** @format */
// Func = limiting Scope for this file

var lastScrollTop = 0;

if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

(function () {
  const paragraph = document.querySelectorAll("p") || [];
  paragraph.forEach(
    (e) => e.innerHTML.trim() === "" && e.parentNode.removeChild(e)
  );

  if (document.getElementById("ot-sdk-btn") && document.getElementById("tertiary-navigation")) {
    document.getElementById("tertiary-navigation").appendChild(document.getElementById("ot-sdk-btn"))
  }

  $(".sym-slides").slick({
    accessibility: false,
  });

  $(".slick-tabs").slick({
    dots: false,
    focusOnSelect: true,
    infinite: true,
    prevArrow: ".arrows__prev",
    nextArrow: ".arrows__next",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          arrows: true,
          fade: true,
        },
      },
    ],
  });

  $(".gallery-slider").slick({
    accessibility: false,
  });

  $(".arrow-prev .slick-prev").click(function () {
    $(".gallery-slider").slick("slickPrev");
  });

  $(".arrow-next .slick-next").click(function () {
    $(".gallery-slider").slick("slickNext");
  });

  $(".gallery-slides").slick({
    nextArrow: "#galNext",
    prevArrow: "#galPrev",
    fade: true,
    asNavFor: ".gallery-slides",
  });
})();

function scrollW() {
  //check scroll down or up for header styles
  let st = window.pageYOffset || document.documentElement.scrollTop;
  if (st > 80) {
    if (st > lastScrollTop) {
      // downscroll code
      if (!document.getElementById("header").classList.contains("down")) {
        document.getElementById("header").classList.add("down");
        document.getElementById("header").classList.remove("up");
      }
    } else {
      // upscroll code
      if (!document.getElementById("header").classList.contains("up")) {
        document.getElementById("header").classList.add("up");
        document.getElementById("header").classList.remove("down");
      }
    }
  } else {
    if (
      document.getElementById("header").classList.contains("up") ||
      document.getElementById("header").classList.contains("down")
    ) {
      document.getElementById("header").classList.remove("up");
      document.getElementById("header").classList.remove("down");
    }
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}
const closeMenu = () => {
  const btnRemoveClass = document.querySelector(
    ".btn--restaurant-menus.active"
  );
  btnRemoveClass.classList.contains("active")
    ? btnRemoveClass.classList.remove("active")
    : "";
  const elementData = btnRemoveClass
    ? document.querySelector(`.active${btnRemoveClass.getAttribute("href")}`)
    : "";
  elementData && elementData.classList.contains("active")
    ? elementData.classList.remove("active")
    : "";
};

const openMenu = (btn) => {
  closeMenu();
  btn.classList.add("active");
  const elementData = document.querySelector(btn.getAttribute("href"));
  elementData.classList.add("active");
};

const initMenus = () => {
  const btns = document.querySelectorAll(
    ".menus-restaurant .btn--restaurant-menus"
  );

  btns.forEach((btn) => {
    btn.addEventListener("click", (eve) => {
      eve.preventDefault();
      eve.target.classList.contains("active") ? "" : openMenu(btn);
    });
  });
};

initMenus();
