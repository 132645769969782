/** @format */

$(function () {
  /*MOBILE MENU NAV
   ****************************************************************/

 
  // Booking Full Screen
  $(".rfp-call").click(function () {
    $(this).toggleClass("active");
    $("#booking-container").toggleClass("open");
    $(".btn-default").fadeToggle();
    $(".btn-close").fadeToggle();
  });

  //Open Navigation
  $("#menu-btn").click(function (event) {
    $("html").addClass("hide-overflow");
    $(".navigation").addClass("open");
    $(".navigation").stop().animate({ left: "0px" }, { duration: 300 });
    if ($(".navigation ul li a").hasClass(".item-open")) {
      $(".item-open").get(0).scrollIntoView(false);
    }
  });

  $(".close-menu").click(function (event) {
    event.preventDefault();
    $("html").removeClass("hide-overflow");
    $(".navigation").removeClass("open");
    $(".navigation")
      .stop()
      .animate({ left: "100%" }, { queue: false, duration: 300 });
  });

  //SUBNAVIGATION

  // Open active sub-nav on load
  $(".navigation ul.submenu li .active").closest(".inner").addClass("open");
  $(".navigation ul.submenu li .active")
    .parents()
    .parents("li")
    .addClass("item-open");
  $(".navigation ul.submenu li .active")
    .closest(".sub-sub-menu")
    .slideDown(300, function () {})
    .addClass("menu-open");

  //Toggle open/close on tap
  $(".navigation li a")
    .off("click")
    .on("click", function (event) {
      if ($(this).parent().children(".sub-sub-menu").length > 0) {
        event.preventDefault();

        if ($(this).next().hasClass("menu-open")) {
          $(this).closest(".inner").removeClass("open");
          $(".navigation ul li").removeClass("item-open");
          $(this).parent().addClass("item-open");
          $(this)
            .next()
            .slideUp(300, function () {
              if ($(".navigation ul li a").hasClass("active")) {
                $(".item-open").get(0).scrollIntoView(false);
              }
            })
            .removeClass("menu-open");
        } else {
          $(this).closest(".inner").addClass("open");
          $(".navigation ul li").removeClass("item-open");
          $(this).parent().addClass("item-open");
          $(".sub-sub-menu.menu-open").slideUp(300).removeClass("menu-open");
          $(this)
            .next()
            .addClass("menu-open")
            .slideDown(300, function () {
              if ($(".navigation ul li a").hasClass("active")) {
                $(".item-open").get(0).scrollIntoView(false);
              }
            });
        }
      }
    });
  window.onload = function () {
    /*this function add a class to header when scroll down the page
      /* and change the logo by a new file if needed depending of mockup
      */

    scrollW();

    window.addEventListener("scroll", function () {
      scrollW();
    });
  };
});
